import { j as u } from "./jsx-runtime-B6kdoens.js";
import { $ as i } from "./index-CWsRuimy.js";
import { forwardRef as c } from "react";
import { c as m } from "./utils-CJ9afRe1.js";
import { c as f } from "./index-BV2fynjD.js";
const b = f("bg-white border", {
  variants: {
    rounded: {
      default: "rounded-md",
      none: "",
      sm: "rounded-sm",
      md: "rounded-md",
      lg: "rounded-lg",
      xl: "rounded-xl",
      "2xl": "rounded-2xl",
      "3xl": "rounded-3xl",
      full: "rounded-full"
    },
    size: {
      default: "p-3"
    },
    border: {
      default: "border-gray-200",
      none: "",
      primary: "border-primary-500",
      secondary: "border-secondary-500",
      success: "border-success-500",
      danger: "border-red-100",
      warning: "border-warning-500",
      info: "border-info-500",
      light: "border-light-500",
      dark: "border-dark-500"
    }
  },
  defaultVariants: {
    size: "default",
    rounded: "default",
    border: "default"
  }
}), w = c((r, d) => {
  const { asChild: e, className: o, size: a, border: n, rounded: s, ...t } = r, l = e ? i : "div";
  return /* @__PURE__ */ u.jsx(
    l,
    {
      ref: d,
      className: m(b({ size: a, className: o, border: n, rounded: s })),
      ...t
    }
  );
});
export {
  w as default
};
