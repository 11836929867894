import { SimpleLabelDto } from "src/backend";
import { useGetLabelsAvailableToMeQuery } from "src/services/loanApi";
import { putLabelOnSomeone } from "src/slices/lender"
import { useDispatch, useSelector } from "src/store"

interface LabelsAvailableToMeProps {
    loanId: string;
}

interface CompanyLabelState {
    labels: SimpleLabelDto[];
    applicantLabels: SimpleLabelDto[];
    lenderLabels: SimpleLabelDto[];
    putLabelOnRole: (labels: SimpleLabelDto[], roleId: string) => Promise<void>;
}

export const useLabelsAvailableToMe = (props: LabelsAvailableToMeProps): CompanyLabelState => {
    const { data: labels = [] } = useGetLabelsAvailableToMeQuery({
        loanId: props.loanId
    });
    const dispatch = useDispatch();

    const handlePutLabelOnRole = async (labels: SimpleLabelDto[], roleId: string) => {
        await dispatch(putLabelOnSomeone({
            loanId: props.loanId,
            labels: labels,
            roleId
        }))
    }
    return {
        labels,
        applicantLabels: labels.filter(l => l.canBeOnBorrower),
        lenderLabels: labels.filter(l => l.canBeOnLender),
        putLabelOnRole: handlePutLabelOnRole
    }
}