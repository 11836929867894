import { PrintEmail } from "./print-email-page.types";

export const printEmailPageInitialValue: PrintEmail = {
    subject: '',
    senderEmail: '',
    receiverEmail: '',
    senderName: '',
    date: '',
    body: '',
}
