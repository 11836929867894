import Box from '@mui/material/Box';
import Tooltip from 'components/dist/atoms/Tooltip';
import { PriorityType } from "src/backend";
import { getFormElementPriorityColor, getPriorityIcon } from "src/utils/form-elements";


type FormElementPriorityProps = {
    priority: PriorityType;
    disabled?: boolean;
    isUserABorrower?: boolean;
};

export const FormElementPriorityChip = (props: FormElementPriorityProps) => {
    const { priority = 'Unset', isUserABorrower } = props;

    // hide the priority chip if isUserABorrower is true and priority is Hidden or Private
    if (isUserABorrower &&
        (priority === "HIDDEN" || priority === 'PRIVATE' || priority === 'Unset' || !priority)) {
        return null;
    }

    return (<Tooltip>
        <Tooltip.Trigger
            aria-hidden
            asChild>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& > svg': {
                    color: `${getFormElementPriorityColor(priority)}.main`,
                }
            }}>
                {getPriorityIcon(priority)}
            </Box>
        </Tooltip.Trigger>
        <Tooltip.Portal>
            <Tooltip.Content
                align="start"
                side="bottom"
                alignOffset={0}
                className="border-0 z-tooltip"
                asChild>
                <div className="p-2 flex rounded-md flex gap-1 items-center">
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        justifyContent: 'center',
                        textTransform: 'capitalize',
                        '& > svg': {
                            color: `${getFormElementPriorityColor(priority)}.main`,
                        }
                    }}>
                        {getPriorityIcon(priority)} {priority.toLocaleLowerCase()}
                    </Box>
                </div>
            </Tooltip.Content>
        </Tooltip.Portal>
    </Tooltip>);
}