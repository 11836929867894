import { g as Se } from "./_commonjsHelpers-DaMA6jEr.js";
import we from "react";
import Ce from "react-dom";
var pe = { exports: {} }, ue, ve;
function Me() {
  if (ve) return ue;
  ve = 1;
  function B(n) {
    return n && typeof n == "object" && "default" in n ? n.default : n;
  }
  var x = B(we), T = Ce;
  function R(n, r) {
    for (var a = Object.getOwnPropertyNames(r), t = 0; t < a.length; t++) {
      var e = a[t], o = Object.getOwnPropertyDescriptor(r, e);
      o && o.configurable && n[e] === void 0 && Object.defineProperty(n, e, o);
    }
    return n;
  }
  function F() {
    return (F = Object.assign || function(n) {
      for (var r = 1; r < arguments.length; r++) {
        var a = arguments[r];
        for (var t in a) Object.prototype.hasOwnProperty.call(a, t) && (n[t] = a[t]);
      }
      return n;
    }).apply(this, arguments);
  }
  function L(n, r) {
    n.prototype = Object.create(r.prototype), R(n.prototype.constructor = n, r);
  }
  function V(n, r) {
    if (n == null) return {};
    var a, t, e = {}, o = Object.keys(n);
    for (t = 0; t < o.length; t++) a = o[t], 0 <= r.indexOf(a) || (e[a] = n[a]);
    return e;
  }
  function D(n) {
    if (n === void 0) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return n;
  }
  var W = function(n, r, a, t, e, o, d, g) {
    if (!n) {
      var s;
      if (r === void 0) s = new Error("Minified exception occurred; use the non-minified dev environment for the full error message and additional helpful warnings.");
      else {
        var c = [a, t, e, o, d, g], p = 0;
        (s = new Error(r.replace(/%s/g, function() {
          return c[p++];
        }))).name = "Invariant Violation";
      }
      throw s.framesToPop = 1, s;
    }
  }, E = W;
  function U(n, r, a) {
    if ("selectionStart" in n && "selectionEnd" in n) n.selectionStart = r, n.selectionEnd = a;
    else {
      var t = n.createTextRange();
      t.collapse(!0), t.moveStart("character", r), t.moveEnd("character", a - r), t.select();
    }
  }
  function $(n) {
    var r = 0, a = 0;
    if ("selectionStart" in n && "selectionEnd" in n) r = n.selectionStart, a = n.selectionEnd;
    else {
      var t = document.selection.createRange();
      t.parentElement() === n && (r = -t.moveStart("character", -n.value.length), a = -t.moveEnd("character", -n.value.length));
    }
    return { start: r, end: a, length: a - r };
  }
  var Q = { 9: "[0-9]", a: "[A-Za-z]", "*": "[A-Za-z0-9]" }, ee = "_";
  function Z(n, r, a) {
    var t = "", e = "", o = null, d = [];
    if (r === void 0 && (r = ee), a == null && (a = Q), !n || typeof n != "string") return { maskChar: r, formatChars: a, mask: null, prefix: null, lastEditablePosition: null, permanents: [] };
    var g = !1;
    return n.split("").forEach(function(s) {
      g = !g && s === "\\" || (g || !a[s] ? (d.push(t.length), t.length === d.length - 1 && (e += s)) : o = t.length + 1, t += s, !1);
    }), { maskChar: r, formatChars: a, prefix: e, mask: t, lastEditablePosition: o, permanents: d };
  }
  function I(n, r) {
    return n.permanents.indexOf(r) !== -1;
  }
  function j(n, r, a) {
    var t = n.mask, e = n.formatChars;
    if (!a) return !1;
    if (I(n, r)) return t[r] === a;
    var o = e[t[r]];
    return new RegExp(o).test(a);
  }
  function H(n, r) {
    return r.split("").every(function(a, t) {
      return I(n, t) || !j(n, t, a);
    });
  }
  function P(n, r) {
    var a = n.maskChar, t = n.prefix;
    if (!a) {
      for (; r.length > t.length && I(n, r.length - 1); ) r = r.slice(0, r.length - 1);
      return r.length;
    }
    for (var e = t.length, o = r.length; o >= t.length; o--) {
      var d = r[o];
      if (!I(n, o) && j(n, o, d)) {
        e = o + 1;
        break;
      }
    }
    return e;
  }
  function K(n, r) {
    return P(n, r) === n.mask.length;
  }
  function b(n, r) {
    var a = n.maskChar, t = n.mask, e = n.prefix;
    if (!a) {
      for ((r = z(n, "", r, 0)).length < e.length && (r = e); r.length < t.length && I(n, r.length); ) r += t[r.length];
      return r;
    }
    if (r) return z(n, b(n, ""), r, 0);
    for (var o = 0; o < t.length; o++) I(n, o) ? r += t[o] : r += a;
    return r;
  }
  function ne(n, r, a, t) {
    var e = a + t, o = n.maskChar, d = n.mask, g = n.prefix, s = r.split("");
    if (o) return s.map(function(p, h) {
      return h < a || e <= h ? p : I(n, h) ? d[h] : o;
    }).join("");
    for (var c = e; c < s.length; c++) I(n, c) && (s[c] = "");
    return a = Math.max(g.length, a), s.splice(a, e - a), r = s.join(""), b(n, r);
  }
  function z(n, r, a, t) {
    var e = n.mask, o = n.maskChar, d = n.prefix, g = a.split(""), s = K(n, r);
    return !o && t > r.length && (r += e.slice(r.length, t)), g.every(function(c) {
      for (; w = c, I(n, u = t) && w !== e[u]; ) {
        if (t >= r.length && (r += e[t]), p = c, h = t, o && I(n, h) && p === o) return !0;
        if (++t >= e.length) return !1;
      }
      var p, h, u, w;
      return !j(n, t, c) && c !== o || (t < r.length ? r = o || s || t < d.length ? r.slice(0, t) + c + r.slice(t + 1) : (r = r.slice(0, t) + c + r.slice(t), b(n, r)) : o || (r += c), ++t < e.length);
    }), r;
  }
  function te(n, r, a, t) {
    var e = n.mask, o = n.maskChar, d = a.split(""), g = t;
    return d.every(function(s) {
      for (; p = s, I(n, c = t) && p !== e[c]; ) if (++t >= e.length) return !1;
      var c, p;
      return (j(n, t, s) || s === o) && t++, t < e.length;
    }), t - g;
  }
  function re(n, r) {
    for (var a = r; 0 <= a; --a) if (!I(n, a)) return a;
    return null;
  }
  function N(n, r) {
    for (var a = n.mask, t = r; t < a.length; ++t) if (!I(n, t)) return t;
    return null;
  }
  function X(n) {
    return n || n === 0 ? n + "" : "";
  }
  function ae(n, r, a, t, e) {
    var o = n.mask, d = n.prefix, g = n.lastEditablePosition, s = r, c = "", p = 0, h = 0, u = Math.min(e.start, a.start);
    return a.end > e.start ? h = (p = te(n, t, c = s.slice(e.start, a.end), u)) ? e.length : 0 : s.length < t.length && (h = t.length - s.length), s = t, h && (h === 1 && !e.length && (u = e.start === a.start ? N(n, a.start) : re(n, a.start)), s = ne(n, s, u, h)), s = z(n, s, c, u), (u += p) >= o.length ? u = o.length : u < d.length && !p ? u = d.length : u >= d.length && u < g && p && (u = N(n, u)), c || (c = null), { value: s = b(n, s), enteredString: c, selection: { start: u, end: u } };
  }
  function oe() {
    var n = new RegExp("windows", "i"), r = new RegExp("phone", "i"), a = navigator.userAgent;
    return n.test(a) && r.test(a);
  }
  function S(n) {
    return typeof n == "function";
  }
  function ie() {
    return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame;
  }
  function G() {
    return window.cancelAnimationFrame || window.webkitCancelRequestAnimationFrame || window.webkitCancelAnimationFrame || window.mozCancelAnimationFrame;
  }
  function J(n) {
    return (G() ? ie() : function() {
      return setTimeout(n, 1e3 / 60);
    })(n);
  }
  function Y(n) {
    (G() || clearTimeout)(n);
  }
  var se = function(n) {
    function r(t) {
      var e = n.call(this, t) || this;
      e.focused = !1, e.mounted = !1, e.previousSelection = null, e.selectionDeferId = null, e.saveSelectionLoopDeferId = null, e.saveSelectionLoop = function() {
        e.previousSelection = e.getSelection(), e.saveSelectionLoopDeferId = J(e.saveSelectionLoop);
      }, e.runSaveSelectionLoop = function() {
        e.saveSelectionLoopDeferId === null && e.saveSelectionLoop();
      }, e.stopSaveSelectionLoop = function() {
        e.saveSelectionLoopDeferId !== null && (Y(e.saveSelectionLoopDeferId), e.saveSelectionLoopDeferId = null, e.previousSelection = null);
      }, e.getInputDOMNode = function() {
        if (!e.mounted) return null;
        var l = T.findDOMNode(D(D(e))), i = typeof window < "u" && l instanceof window.Element;
        if (l && !i) return null;
        if (l.nodeName !== "INPUT" && (l = l.querySelector("input")), !l) throw new Error("react-input-mask: inputComponent doesn't contain input node");
        return l;
      }, e.getInputValue = function() {
        var l = e.getInputDOMNode();
        return l ? l.value : null;
      }, e.setInputValue = function(l) {
        var i = e.getInputDOMNode();
        i && (e.value = l, i.value = l);
      }, e.setCursorToEnd = function() {
        var l = P(e.maskOptions, e.value), i = N(e.maskOptions, l);
        i !== null && e.setCursorPosition(i);
      }, e.setSelection = function(l, i, f) {
        f === void 0 && (f = {});
        var v = e.getInputDOMNode(), m = e.isFocused();
        v && m && (f.deferred || U(v, l, i), e.selectionDeferId !== null && Y(e.selectionDeferId), e.selectionDeferId = J(function() {
          e.selectionDeferId = null, U(v, l, i);
        }), e.previousSelection = { start: l, end: i, length: Math.abs(i - l) });
      }, e.getSelection = function() {
        return $(e.getInputDOMNode());
      }, e.getCursorPosition = function() {
        return e.getSelection().start;
      }, e.setCursorPosition = function(l) {
        e.setSelection(l, l);
      }, e.isFocused = function() {
        return e.focused;
      }, e.getBeforeMaskedValueChangeConfig = function() {
        var l = e.maskOptions, i = l.mask, f = l.maskChar, v = l.permanents, m = l.formatChars;
        return { mask: i, maskChar: f, permanents: v, alwaysShowMask: !!e.props.alwaysShowMask, formatChars: m };
      }, e.isInputAutofilled = function(l, i, f, v) {
        var m = e.getInputDOMNode();
        try {
          if (m.matches(":-webkit-autofill")) return !0;
        } catch {
        }
        return !e.focused || v.end < f.length && i.end === l.length;
      }, e.onChange = function(l) {
        var i = D(D(e)).beforePasteState, f = D(D(e)).previousSelection, v = e.props.beforeMaskedValueChange, m = e.getInputValue(), k = e.value, C = e.getSelection();
        e.isInputAutofilled(m, C, k, f) && (k = b(e.maskOptions, ""), f = { start: 0, end: 0, length: 0 }), i && (f = i.selection, k = i.value, C = { start: f.start + m.length, end: f.start + m.length, length: 0 }, m = k.slice(0, f.start) + m + k.slice(f.end), e.beforePasteState = null);
        var M = ae(e.maskOptions, m, C, k, f), y = M.enteredString, O = M.selection, _ = M.value;
        if (S(v)) {
          var A = v({ value: _, selection: O }, { value: k, selection: f }, y, e.getBeforeMaskedValueChangeConfig());
          _ = A.value, O = A.selection;
        }
        e.setInputValue(_), S(e.props.onChange) && e.props.onChange(l), e.isWindowsPhoneBrowser ? e.setSelection(O.start, O.end, { deferred: !0 }) : e.setSelection(O.start, O.end);
      }, e.onFocus = function(l) {
        var i = e.props.beforeMaskedValueChange, f = e.maskOptions, v = f.mask, m = f.prefix;
        if (e.focused = !0, e.mounted = !0, v) {
          if (e.value) P(e.maskOptions, e.value) < e.maskOptions.mask.length && e.setCursorToEnd();
          else {
            var k = b(e.maskOptions, m), C = b(e.maskOptions, k), M = P(e.maskOptions, C), y = N(e.maskOptions, M), O = { start: y, end: y };
            if (S(i)) {
              var _ = i({ value: C, selection: O }, { value: e.value, selection: null }, null, e.getBeforeMaskedValueChangeConfig());
              C = _.value, O = _.selection;
            }
            var A = C !== e.getInputValue();
            A && e.setInputValue(C), A && S(e.props.onChange) && e.props.onChange(l), e.setSelection(O.start, O.end);
          }
          e.runSaveSelectionLoop();
        }
        S(e.props.onFocus) && e.props.onFocus(l);
      }, e.onBlur = function(l) {
        var i = e.props.beforeMaskedValueChange, f = e.maskOptions.mask;
        if (e.stopSaveSelectionLoop(), e.focused = !1, f && !e.props.alwaysShowMask && H(e.maskOptions, e.value)) {
          var v = "";
          S(i) && (v = i({ value: v, selection: null }, { value: e.value, selection: e.previousSelection }, null, e.getBeforeMaskedValueChangeConfig()).value);
          var m = v !== e.getInputValue();
          m && e.setInputValue(v), m && S(e.props.onChange) && e.props.onChange(l);
        }
        S(e.props.onBlur) && e.props.onBlur(l);
      }, e.onMouseDown = function(l) {
        if (!e.focused && document.addEventListener) {
          e.mouseDownX = l.clientX, e.mouseDownY = l.clientY, e.mouseDownTime = (/* @__PURE__ */ new Date()).getTime();
          var i = function f(v) {
            if (document.removeEventListener("mouseup", f), e.focused) {
              var m = Math.abs(v.clientX - e.mouseDownX), k = Math.abs(v.clientY - e.mouseDownY), C = Math.max(m, k), M = (/* @__PURE__ */ new Date()).getTime() - e.mouseDownTime;
              (C <= 10 && M <= 200 || C <= 5 && M <= 300) && e.setCursorToEnd();
            }
          };
          document.addEventListener("mouseup", i);
        }
        S(e.props.onMouseDown) && e.props.onMouseDown(l);
      }, e.onPaste = function(l) {
        S(e.props.onPaste) && e.props.onPaste(l), l.defaultPrevented || (e.beforePasteState = { value: e.getInputValue(), selection: e.getSelection() }, e.setInputValue(""));
      }, e.handleRef = function(l) {
        e.props.children == null && S(e.props.inputRef) && e.props.inputRef(l);
      };
      var o = t.mask, d = t.maskChar, g = t.formatChars, s = t.alwaysShowMask, c = t.beforeMaskedValueChange, p = t.defaultValue, h = t.value;
      e.maskOptions = Z(o, d, g), p == null && (p = ""), h == null && (h = p);
      var u = X(h);
      if (e.maskOptions.mask && (s || u) && (u = b(e.maskOptions, u), S(c))) {
        var w = t.value;
        t.value == null && (w = p), u = c({ value: u, selection: null }, { value: w = X(w), selection: null }, null, e.getBeforeMaskedValueChangeConfig()).value;
      }
      return e.value = u, e;
    }
    L(r, n);
    var a = r.prototype;
    return a.componentDidMount = function() {
      this.mounted = !0, this.getInputDOMNode() && (this.isWindowsPhoneBrowser = oe(), this.maskOptions.mask && this.getInputValue() !== this.value && this.setInputValue(this.value));
    }, a.componentDidUpdate = function() {
      var t = this.previousSelection, e = this.props, o = e.beforeMaskedValueChange, d = e.alwaysShowMask, g = e.mask, s = e.maskChar, c = e.formatChars, p = this.maskOptions, h = d || this.isFocused(), u = this.props.value != null, w = u ? X(this.props.value) : this.value, l = t ? t.start : null;
      if (this.maskOptions = Z(g, s, c), this.maskOptions.mask) {
        !p.mask && this.isFocused() && this.runSaveSelectionLoop();
        var i = this.maskOptions.mask && this.maskOptions.mask !== p.mask;
        if (p.mask || u || (w = this.getInputValue()), (i || this.maskOptions.mask && (w || h)) && (w = b(this.maskOptions, w)), i) {
          var f = P(this.maskOptions, w);
          (l === null || f < l) && (l = K(this.maskOptions, w) ? f : N(this.maskOptions, f));
        }
        !this.maskOptions.mask || !H(this.maskOptions, w) || h || u && this.props.value || (w = "");
        var v = { start: l, end: l };
        if (S(o)) {
          var m = o({ value: w, selection: v }, { value: this.value, selection: this.previousSelection }, null, this.getBeforeMaskedValueChangeConfig());
          w = m.value, v = m.selection;
        }
        this.value = w;
        var k = this.getInputValue() !== this.value;
        k ? (this.setInputValue(this.value), this.forceUpdate()) : i && this.forceUpdate();
        var C = !1;
        v.start != null && v.end != null && (C = !t || t.start !== v.start || t.end !== v.end), (C || k) && this.setSelection(v.start, v.end);
      } else p.mask && (this.stopSaveSelectionLoop(), this.forceUpdate());
    }, a.componentWillUnmount = function() {
      this.mounted = !1, this.selectionDeferId !== null && Y(this.selectionDeferId), this.stopSaveSelectionLoop();
    }, a.render = function() {
      var t, e = this.props, o = (e.mask, e.alwaysShowMask, e.maskChar, e.formatChars, e.inputRef, e.beforeMaskedValueChange, e.children), d = V(e, ["mask", "alwaysShowMask", "maskChar", "formatChars", "inputRef", "beforeMaskedValueChange", "children"]);
      if (o) {
        S(o) || E(!1);
        var g = ["onChange", "onPaste", "onMouseDown", "onFocus", "onBlur", "value", "disabled", "readOnly"], s = F({}, d);
        g.forEach(function(p) {
          return delete s[p];
        }), t = o(s), g.filter(function(p) {
          return t.props[p] != null && t.props[p] !== d[p];
        }).length && E(!1);
      } else t = x.createElement("input", F({ ref: this.handleRef }, d));
      var c = { onFocus: this.onFocus, onBlur: this.onBlur };
      return this.maskOptions.mask && (d.disabled || d.readOnly || (c.onChange = this.onChange, c.onPaste = this.onPaste, c.onMouseDown = this.onMouseDown), d.value != null && (c.value = this.value)), t = x.cloneElement(t, c);
    }, r;
  }(x.Component);
  return ue = se, ue;
}
var fe, me;
function Ie() {
  if (me) return fe;
  me = 1;
  var B = function(x, T, R, F, L, V, D, W) {
    if (process.env.NODE_ENV !== "production" && T === void 0)
      throw new Error("invariant requires an error message argument");
    if (!x) {
      var E;
      if (T === void 0)
        E = new Error(
          "Minified exception occurred; use the non-minified dev environment for the full error message and additional helpful warnings."
        );
      else {
        var U = [R, F, L, V, D, W], $ = 0;
        E = new Error(
          T.replace(/%s/g, function() {
            return U[$++];
          })
        ), E.name = "Invariant Violation";
      }
      throw E.framesToPop = 1, E;
    }
  };
  return fe = B, fe;
}
var ce, ge;
function De() {
  if (ge) return ce;
  ge = 1;
  var B = process.env.NODE_ENV !== "production", x = function() {
  };
  if (B) {
    var T = function(F, L) {
      var V = arguments.length;
      L = new Array(V > 1 ? V - 1 : 0);
      for (var D = 1; D < V; D++)
        L[D - 1] = arguments[D];
      var W = 0, E = "Warning: " + F.replace(/%s/g, function() {
        return L[W++];
      });
      typeof console < "u" && console.error(E);
      try {
        throw new Error(E);
      } catch {
      }
    };
    x = function(R, F, L) {
      var V = arguments.length;
      L = new Array(V > 2 ? V - 2 : 0);
      for (var D = 2; D < V; D++)
        L[D - 2] = arguments[D];
      if (F === void 0)
        throw new Error(
          "`warning(condition, format, ...args)` requires a warning message argument"
        );
      R || T.apply(null, [F].concat(L));
    };
  }
  return ce = x, ce;
}
var he, ke;
function be() {
  if (ke) return he;
  ke = 1;
  function B(n) {
    return n && typeof n == "object" && "default" in n ? n.default : n;
  }
  var x = B(we), T = Ce, R = B(Ie()), F = B(De());
  function L(n, r) {
    for (var a = Object.getOwnPropertyNames(r), t = 0; t < a.length; t++) {
      var e = a[t], o = Object.getOwnPropertyDescriptor(r, e);
      o && o.configurable && n[e] === void 0 && Object.defineProperty(n, e, o);
    }
    return n;
  }
  function V() {
    return V = Object.assign || function(n) {
      for (var r = 1; r < arguments.length; r++) {
        var a = arguments[r];
        for (var t in a)
          Object.prototype.hasOwnProperty.call(a, t) && (n[t] = a[t]);
      }
      return n;
    }, V.apply(this, arguments);
  }
  function D(n, r) {
    n.prototype = Object.create(r.prototype), n.prototype.constructor = n, L(n, r);
  }
  function W(n, r) {
    if (n == null) return {};
    var a = {}, t = Object.keys(n), e, o;
    for (o = 0; o < t.length; o++)
      e = t[o], !(r.indexOf(e) >= 0) && (a[e] = n[e]);
    return a;
  }
  function E(n) {
    if (n === void 0)
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return n;
  }
  function U(n, r, a) {
    if ("selectionStart" in n && "selectionEnd" in n)
      n.selectionStart = r, n.selectionEnd = a;
    else {
      var t = n.createTextRange();
      t.collapse(!0), t.moveStart("character", r), t.moveEnd("character", a - r), t.select();
    }
  }
  function $(n) {
    var r = 0, a = 0;
    if ("selectionStart" in n && "selectionEnd" in n)
      r = n.selectionStart, a = n.selectionEnd;
    else {
      var t = document.selection.createRange();
      t.parentElement() === n && (r = -t.moveStart("character", -n.value.length), a = -t.moveEnd("character", -n.value.length));
    }
    return {
      start: r,
      end: a,
      length: a - r
    };
  }
  var Q = {
    9: "[0-9]",
    a: "[A-Za-z]",
    "*": "[A-Za-z0-9]"
  }, ee = "_";
  function Z(n, r, a) {
    var t = "", e = "", o = null, d = [];
    if (r === void 0 && (r = ee), a == null && (a = Q), !n || typeof n != "string")
      return {
        maskChar: r,
        formatChars: a,
        mask: null,
        prefix: null,
        lastEditablePosition: null,
        permanents: []
      };
    var g = !1;
    return n.split("").forEach(function(s) {
      !g && s === "\\" ? g = !0 : (g || !a[s] ? (d.push(t.length), t.length === d.length - 1 && (e += s)) : o = t.length + 1, t += s, g = !1);
    }), {
      maskChar: r,
      formatChars: a,
      prefix: e,
      mask: t,
      lastEditablePosition: o,
      permanents: d
    };
  }
  function I(n, r) {
    return n.permanents.indexOf(r) !== -1;
  }
  function j(n, r, a) {
    var t = n.mask, e = n.formatChars;
    if (!a)
      return !1;
    if (I(n, r))
      return t[r] === a;
    var o = t[r], d = e[o];
    return new RegExp(d).test(a);
  }
  function H(n, r) {
    return r.split("").every(function(a, t) {
      return I(n, t) || !j(n, t, a);
    });
  }
  function P(n, r) {
    var a = n.maskChar, t = n.prefix;
    if (!a) {
      for (; r.length > t.length && I(n, r.length - 1); )
        r = r.slice(0, r.length - 1);
      return r.length;
    }
    for (var e = t.length, o = r.length; o >= t.length; o--) {
      var d = r[o], g = !I(n, o) && j(n, o, d);
      if (g) {
        e = o + 1;
        break;
      }
    }
    return e;
  }
  function K(n, r) {
    return P(n, r) === n.mask.length;
  }
  function b(n, r) {
    var a = n.maskChar, t = n.mask, e = n.prefix;
    if (!a) {
      for (r = z(n, "", r, 0), r.length < e.length && (r = e); r.length < t.length && I(n, r.length); )
        r += t[r.length];
      return r;
    }
    if (r) {
      var o = b(n, "");
      return z(n, o, r, 0);
    }
    for (var d = 0; d < t.length; d++)
      I(n, d) ? r += t[d] : r += a;
    return r;
  }
  function ne(n, r, a, t) {
    var e = a + t, o = n.maskChar, d = n.mask, g = n.prefix, s = r.split("");
    if (!o) {
      for (var c = e; c < s.length; c++)
        I(n, c) && (s[c] = "");
      return a = Math.max(g.length, a), s.splice(a, e - a), r = s.join(""), b(n, r);
    }
    return s.map(function(p, h) {
      return h < a || h >= e ? p : I(n, h) ? d[h] : o;
    }).join("");
  }
  function z(n, r, a, t) {
    var e = n.mask, o = n.maskChar, d = n.prefix, g = a.split(""), s = K(n, r), c = function(u, w) {
      return !I(n, u) || w === e[u];
    }, p = function(u, w) {
      return !o || !I(n, w) || u !== o;
    };
    return !o && t > r.length && (r += e.slice(r.length, t)), g.every(function(h) {
      for (; !c(t, h); ) {
        if (t >= r.length && (r += e[t]), !p(h, t))
          return !0;
        if (t++, t >= e.length)
          return !1;
      }
      var u = j(n, t, h) || h === o;
      return u ? (t < r.length ? o || s || t < d.length ? r = r.slice(0, t) + h + r.slice(t + 1) : (r = r.slice(0, t) + h + r.slice(t), r = b(n, r)) : o || (r += h), t++, t < e.length) : !0;
    }), r;
  }
  function te(n, r, a, t) {
    var e = n.mask, o = n.maskChar, d = a.split(""), g = t, s = function(p, h) {
      return !I(n, p) || h === e[p];
    };
    return d.every(function(c) {
      for (; !s(t, c); )
        if (t++, t >= e.length)
          return !1;
      var p = j(n, t, c) || c === o;
      return p && t++, t < e.length;
    }), t - g;
  }
  function re(n, r) {
    for (var a = r; a >= 0; --a)
      if (!I(n, a))
        return a;
    return null;
  }
  function N(n, r) {
    for (var a = n.mask, t = r; t < a.length; ++t)
      if (!I(n, t))
        return t;
    return null;
  }
  function X(n) {
    return !n && n !== 0 ? "" : n + "";
  }
  function ae(n, r, a, t, e) {
    var o = n.mask, d = n.prefix, g = n.lastEditablePosition, s = r, c = "", p = 0, h = 0, u = Math.min(e.start, a.start);
    if (a.end > e.start ? (c = s.slice(e.start, a.end), p = te(n, t, c, u), p ? h = e.length : h = 0) : s.length < t.length && (h = t.length - s.length), s = t, h) {
      if (h === 1 && !e.length) {
        var w = e.start === a.start;
        u = w ? N(n, a.start) : re(n, a.start);
      }
      s = ne(n, s, u, h);
    }
    return s = z(n, s, c, u), u = u + p, u >= o.length ? u = o.length : u < d.length && !p ? u = d.length : u >= d.length && u < g && p && (u = N(n, u)), s = b(n, s), c || (c = null), {
      value: s,
      enteredString: c,
      selection: {
        start: u,
        end: u
      }
    };
  }
  function oe() {
    var n = new RegExp("windows", "i"), r = new RegExp("phone", "i"), a = navigator.userAgent;
    return n.test(a) && r.test(a);
  }
  function S(n) {
    return typeof n == "function";
  }
  function ie() {
    return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame;
  }
  function G() {
    return window.cancelAnimationFrame || window.webkitCancelRequestAnimationFrame || window.webkitCancelAnimationFrame || window.mozCancelAnimationFrame;
  }
  function J(n) {
    var r = !!G(), a;
    return r ? a = ie() : a = function() {
      return setTimeout(n, 1e3 / 60);
    }, a(n);
  }
  function Y(n) {
    var r = G() || clearTimeout;
    r(n);
  }
  var se = /* @__PURE__ */ function(n) {
    D(r, n);
    function r(t) {
      var e;
      e = n.call(this, t) || this, e.focused = !1, e.mounted = !1, e.previousSelection = null, e.selectionDeferId = null, e.saveSelectionLoopDeferId = null, e.saveSelectionLoop = function() {
        e.previousSelection = e.getSelection(), e.saveSelectionLoopDeferId = J(e.saveSelectionLoop);
      }, e.runSaveSelectionLoop = function() {
        e.saveSelectionLoopDeferId === null && e.saveSelectionLoop();
      }, e.stopSaveSelectionLoop = function() {
        e.saveSelectionLoopDeferId !== null && (Y(e.saveSelectionLoopDeferId), e.saveSelectionLoopDeferId = null, e.previousSelection = null);
      }, e.getInputDOMNode = function() {
        if (!e.mounted)
          return null;
        var i = T.findDOMNode(E(E(e))), f = typeof window < "u" && i instanceof window.Element;
        if (i && !f)
          return null;
        if (i.nodeName !== "INPUT" && (i = i.querySelector("input")), !i)
          throw new Error("react-input-mask: inputComponent doesn't contain input node");
        return i;
      }, e.getInputValue = function() {
        var i = e.getInputDOMNode();
        return i ? i.value : null;
      }, e.setInputValue = function(i) {
        var f = e.getInputDOMNode();
        f && (e.value = i, f.value = i);
      }, e.setCursorToEnd = function() {
        var i = P(e.maskOptions, e.value), f = N(e.maskOptions, i);
        f !== null && e.setCursorPosition(f);
      }, e.setSelection = function(i, f, v) {
        v === void 0 && (v = {});
        var m = e.getInputDOMNode(), k = e.isFocused();
        if (!(!m || !k)) {
          var C = v, M = C.deferred;
          M || U(m, i, f), e.selectionDeferId !== null && Y(e.selectionDeferId), e.selectionDeferId = J(function() {
            e.selectionDeferId = null, U(m, i, f);
          }), e.previousSelection = {
            start: i,
            end: f,
            length: Math.abs(f - i)
          };
        }
      }, e.getSelection = function() {
        var i = e.getInputDOMNode();
        return $(i);
      }, e.getCursorPosition = function() {
        return e.getSelection().start;
      }, e.setCursorPosition = function(i) {
        e.setSelection(i, i);
      }, e.isFocused = function() {
        return e.focused;
      }, e.getBeforeMaskedValueChangeConfig = function() {
        var i = e.maskOptions, f = i.mask, v = i.maskChar, m = i.permanents, k = i.formatChars, C = e.props.alwaysShowMask;
        return {
          mask: f,
          maskChar: v,
          permanents: m,
          alwaysShowMask: !!C,
          formatChars: k
        };
      }, e.isInputAutofilled = function(i, f, v, m) {
        var k = e.getInputDOMNode();
        try {
          if (k.matches(":-webkit-autofill"))
            return !0;
        } catch {
        }
        return e.focused ? m.end < v.length && f.end === i.length : !0;
      }, e.onChange = function(i) {
        var f = E(E(e)), v = f.beforePasteState, m = E(E(e)), k = m.previousSelection, C = e.props.beforeMaskedValueChange, M = e.getInputValue(), y = e.value, O = e.getSelection();
        e.isInputAutofilled(M, O, y, k) && (y = b(e.maskOptions, ""), k = {
          start: 0,
          end: 0,
          length: 0
        }), v && (k = v.selection, y = v.value, O = {
          start: k.start + M.length,
          end: k.start + M.length,
          length: 0
        }, M = y.slice(0, k.start) + M + y.slice(k.end), e.beforePasteState = null);
        var _ = ae(e.maskOptions, M, O, y, k), A = _.enteredString, q = _.selection, le = _.value;
        if (S(C)) {
          var de = C({
            value: le,
            selection: q
          }, {
            value: y,
            selection: k
          }, A, e.getBeforeMaskedValueChangeConfig());
          le = de.value, q = de.selection;
        }
        e.setInputValue(le), S(e.props.onChange) && e.props.onChange(i), e.isWindowsPhoneBrowser ? e.setSelection(q.start, q.end, {
          deferred: !0
        }) : e.setSelection(q.start, q.end);
      }, e.onFocus = function(i) {
        var f = e.props.beforeMaskedValueChange, v = e.maskOptions, m = v.mask, k = v.prefix;
        if (e.focused = !0, e.mounted = !0, m) {
          if (e.value)
            P(e.maskOptions, e.value) < e.maskOptions.mask.length && e.setCursorToEnd();
          else {
            var C = b(e.maskOptions, k), M = b(e.maskOptions, C), y = P(e.maskOptions, M), O = N(e.maskOptions, y), _ = {
              start: O,
              end: O
            };
            if (S(f)) {
              var A = f({
                value: M,
                selection: _
              }, {
                value: e.value,
                selection: null
              }, null, e.getBeforeMaskedValueChangeConfig());
              M = A.value, _ = A.selection;
            }
            var q = M !== e.getInputValue();
            q && e.setInputValue(M), q && S(e.props.onChange) && e.props.onChange(i), e.setSelection(_.start, _.end);
          }
          e.runSaveSelectionLoop();
        }
        S(e.props.onFocus) && e.props.onFocus(i);
      }, e.onBlur = function(i) {
        var f = e.props.beforeMaskedValueChange, v = e.maskOptions.mask;
        if (e.stopSaveSelectionLoop(), e.focused = !1, v && !e.props.alwaysShowMask && H(e.maskOptions, e.value)) {
          var m = "";
          if (S(f)) {
            var k = f({
              value: m,
              selection: null
            }, {
              value: e.value,
              selection: e.previousSelection
            }, null, e.getBeforeMaskedValueChangeConfig());
            m = k.value;
          }
          var C = m !== e.getInputValue();
          C && e.setInputValue(m), C && S(e.props.onChange) && e.props.onChange(i);
        }
        S(e.props.onBlur) && e.props.onBlur(i);
      }, e.onMouseDown = function(i) {
        if (!e.focused && document.addEventListener) {
          e.mouseDownX = i.clientX, e.mouseDownY = i.clientY, e.mouseDownTime = (/* @__PURE__ */ new Date()).getTime();
          var f = function v(m) {
            if (document.removeEventListener("mouseup", v), !!e.focused) {
              var k = Math.abs(m.clientX - e.mouseDownX), C = Math.abs(m.clientY - e.mouseDownY), M = Math.max(k, C), y = (/* @__PURE__ */ new Date()).getTime() - e.mouseDownTime;
              (M <= 10 && y <= 200 || M <= 5 && y <= 300) && e.setCursorToEnd();
            }
          };
          document.addEventListener("mouseup", f);
        }
        S(e.props.onMouseDown) && e.props.onMouseDown(i);
      }, e.onPaste = function(i) {
        S(e.props.onPaste) && e.props.onPaste(i), i.defaultPrevented || (e.beforePasteState = {
          value: e.getInputValue(),
          selection: e.getSelection()
        }, e.setInputValue(""));
      }, e.handleRef = function(i) {
        e.props.children == null && S(e.props.inputRef) && e.props.inputRef(i);
      };
      var o = t.mask, d = t.maskChar, g = t.formatChars, s = t.alwaysShowMask, c = t.beforeMaskedValueChange, p = t.defaultValue, h = t.value;
      e.maskOptions = Z(o, d, g), p == null && (p = ""), h == null && (h = p);
      var u = X(h);
      if (e.maskOptions.mask && (s || u) && (u = b(e.maskOptions, u), S(c))) {
        var w = t.value;
        t.value == null && (w = p), w = X(w);
        var l = c({
          value: u,
          selection: null
        }, {
          value: w,
          selection: null
        }, null, e.getBeforeMaskedValueChangeConfig());
        u = l.value;
      }
      return e.value = u, e;
    }
    var a = r.prototype;
    return a.componentDidMount = function() {
      this.mounted = !0, this.getInputDOMNode() && (this.isWindowsPhoneBrowser = oe(), this.maskOptions.mask && this.getInputValue() !== this.value && this.setInputValue(this.value));
    }, a.componentDidUpdate = function() {
      var e = this.previousSelection, o = this.props, d = o.beforeMaskedValueChange, g = o.alwaysShowMask, s = o.mask, c = o.maskChar, p = o.formatChars, h = this.maskOptions, u = g || this.isFocused(), w = this.props.value != null, l = w ? X(this.props.value) : this.value, i = e ? e.start : null;
      if (this.maskOptions = Z(s, c, p), this.maskOptions.mask)
        !h.mask && this.isFocused() && this.runSaveSelectionLoop();
      else {
        h.mask && (this.stopSaveSelectionLoop(), this.forceUpdate());
        return;
      }
      var f = this.maskOptions.mask && this.maskOptions.mask !== h.mask;
      if (!h.mask && !w && (l = this.getInputValue()), (f || this.maskOptions.mask && (l || u)) && (l = b(this.maskOptions, l)), f) {
        var v = P(this.maskOptions, l);
        (i === null || v < i) && (K(this.maskOptions, l) ? i = v : i = N(this.maskOptions, v));
      }
      this.maskOptions.mask && H(this.maskOptions, l) && !u && (!w || !this.props.value) && (l = "");
      var m = {
        start: i,
        end: i
      };
      if (S(d)) {
        var k = d({
          value: l,
          selection: m
        }, {
          value: this.value,
          selection: this.previousSelection
        }, null, this.getBeforeMaskedValueChangeConfig());
        l = k.value, m = k.selection;
      }
      this.value = l;
      var C = this.getInputValue() !== this.value;
      C ? (this.setInputValue(this.value), this.forceUpdate()) : f && this.forceUpdate();
      var M = !1;
      m.start != null && m.end != null && (M = !e || e.start !== m.start || e.end !== m.end), (M || C) && this.setSelection(m.start, m.end);
    }, a.componentWillUnmount = function() {
      this.mounted = !1, this.selectionDeferId !== null && Y(this.selectionDeferId), this.stopSaveSelectionLoop();
    }, a.render = function() {
      var e = this.props, o = e.mask;
      e.alwaysShowMask;
      var d = e.maskChar, g = e.formatChars, s = e.inputRef;
      e.beforeMaskedValueChange;
      var c = e.children, p = W(e, ["mask", "alwaysShowMask", "maskChar", "formatChars", "inputRef", "beforeMaskedValueChange", "children"]), h;
      if (process.env.NODE_ENV !== "production" && F(
        // parse mask to test against actual mask prop as this.maskOptions
        // will be updated later in componentDidUpdate
        !p.maxLength || !Z(o, d, g).mask,
        "react-input-mask: maxLength property shouldn't be passed to the masked input. It breaks masking and unnecessary because length is limited by the mask length."
      ), c) {
        S(c) || (process.env.NODE_ENV !== "production" ? R(!1, "react-input-mask: children must be a function") : R(!1));
        var u = ["onChange", "onPaste", "onMouseDown", "onFocus", "onBlur", "value", "disabled", "readOnly"], w = V({}, p);
        u.forEach(function(f) {
          return delete w[f];
        }), h = c(w);
        var l = u.filter(function(f) {
          return h.props[f] != null && h.props[f] !== p[f];
        });
        l.length && (process.env.NODE_ENV !== "production" ? R(!1, "react-input-mask: the following props should be passed to the react-input-mask's component and should not be altered in children's function: " + l.join(", ")) : R(!1)), process.env.NODE_ENV !== "production" && F(!s, "react-input-mask: inputRef is ignored when children is passed, attach ref to the children instead");
      } else
        h = x.createElement("input", V({
          ref: this.handleRef
        }, p));
      var i = {
        onFocus: this.onFocus,
        onBlur: this.onBlur
      };
      return this.maskOptions.mask && (!p.disabled && !p.readOnly && (i.onChange = this.onChange, i.onPaste = this.onPaste, i.onMouseDown = this.onMouseDown), p.value != null && (i.value = this.value)), h = x.cloneElement(h, i), h;
    }, r;
  }(x.Component);
  return he = se, he;
}
process.env.NODE_ENV === "production" ? pe.exports = Me() : pe.exports = be();
var Ee = pe.exports;
const _e = /* @__PURE__ */ Se(Ee);
export {
  _e as I
};
