import { useGetLoanElementsQuery } from "src/services/packageApi";

interface GetElementsProps {
    loanId: string;
}

export const useGetElements = (props: GetElementsProps) => {

    const { data, isLoading, refetch } = useGetLoanElementsQuery({
        id: props.loanId,
        view: 'CONVENTIONAL'
    }, {
        skip: !props.loanId
    })

    return {
        refetch,
        list: data?.list ?? [],
        links: data?.links ?? [],
        isLoading: isLoading,
        tree: data?.tree ?? [],
        rootElementId: data?.rootElementId,
        totalFileElements: data?.totalFileElements ?? 0,
        totalFolderElements: data?.totalFolderElements ?? 0,
    }
};