import { useRouter } from "next/router";
import React from "react";
import { toast } from "react-toastify";
import type { AppUserDTO2, LoanRoleDto } from "src/backend";
import { RoleType } from "src/constants/loan";
import { Route } from "src/constants/ui";
import { Permission } from "src/constants/user";
import { useContactContext } from "src/contexts/contact-context";
import { useMessagesContext } from "src/contexts/messages-context";
import { useLabelsAvailableToMe } from "src/hooks/use-labels-available-to-me";
import { useLenderEmployees } from 'src/hooks/use-lender-employees';
import { useUser } from "src/hooks/use-user";
import { useGetLoanByIdQuery } from "src/services/loanApi";
import { addLenderToLoan, getLoan } from "src/slices/loan";
import { loansManagerSlice } from "src/slices/loan-manager";
import { inviteUserToLoan } from "src/slices/person";
import { useDispatch } from "src/store";
import { Loan, LoanRole } from "src/types/loan"
import { getUserLoanProgress } from "src/utils/get-user-loan-progress";
import { getUserDisplayName } from "src/utils/user/get-user-display-name";
import { isRoleABorrower } from "src/utils/user/is-role-a-borrower";
import { roleCan } from "src/utils/user/role-can";

import { useRoleActionsMenuHook } from "../role-actions-menu/role-actions-menu.hook";

interface LoanOverviewTeamHookProps {
    loan: Loan;
}

interface TeamGroupRole extends LoanRole {
    isMe: boolean;
    user: AppUserDTO2 & {
        hasPendingInvite: boolean;
    };
    assigned: number;
    percent: number;
    uploaded: number;
}

interface TeamGroup {
    title: string;
    roles: TeamGroupRole[];
}

export const useLoanOverviewTeamHook = (props: LoanOverviewTeamHookProps) => {
    const [invitePersonDialogOpen, setInvitePersonDialogOpen] = React.useState(false);
    const messagesContext = useMessagesContext();
    const contactContext = useContactContext();
    const { data: loanByIdData } = useGetLoanByIdQuery(props.loan.id);
    const { lenderLabels, applicantLabels } = useLabelsAvailableToMe({
        loanId: props.loan?.id
    });

    const router = useRouter();

    const handleSendMessageClick = (userId: string) => {
        messagesContext.onSendMessageClick([], { loanId: props.loan.id, recipients: [userId], shoeboxItemsIds: [] })
    }
    const { userRole, user: loggedInUser } = useUser({
        loan: props.loan
    });

    const { onDelete, onEdit } = useRoleActionsMenuHook();

    const { employees } = useLenderEmployees({
        lenderId: props.loan?.lender?.id,
        skip: !userRole || !roleCan(userRole, Permission.CrudAssociate)
    });

    const dispatch = useDispatch();

    const handleEditClick = (roleId: string) => {
        const loanRole = props.loan.loanRoles.find((role) => role.id === roleId);
        if (loanRole.keyContact) {
            return router.push({
                pathname: Route.LOAN_CONTACT_KEY_CONTACT_EDIT,
                query: {
                    loanId: props.loan.id,
                    loanRoleId: roleId,
                }
            })
        }
        if (isRoleABorrower(loanRole.role)) {
            onEdit({ id: roleId, loanId: props.loan.id, type: 'PERSON' });
        } else {
            onEdit({ id: roleId, loanId: props.loan.id, type: 'ROLE' });

        }
    }

    const handleDeleteRole = async (roleId: string) => {
        await onDelete({ roleId, loanId: props.loan.id });
        const roleOnLoan = props.loan.loanRoles.find((role) => role.id === roleId);
        if (roleOnLoan.keyContact) {
            toast.success(`${getUserDisplayName(roleOnLoan.user)} removed from the loan`);
        }
        // if the role we removed is the current user's role, 
        // and the loan review status is not ready for underwriting,
        // then we need to redirect them to the dashboard
        if (roleOnLoan &&
            loggedInUser.loggedCompanyRole === "UNDERWRITER_LENDER" &&
            roleOnLoan.user.id === loggedInUser.id &&
            props.loan.reviewStatus !== 'READY_FOR_UNDERWRITING') {
            // remove loan from redux
            dispatch(loansManagerSlice.actions.removeLoan(props.loan.id));
            router.push({
                pathname: Route.HOME,
            })
        }
    }

    const handleAddLenderClick = () => {
        router.push({
            pathname: Route.ADD_LOAN_LENDER_ROLE,
            query: {
                loanId: props.loan.id,
            }
        })
    }

    const handleAddBorrowerClick = () => {
        router.push({
            pathname: Route.CREATE_LOAN_PRINCIPAL,
            query: {
                loanId: props.loan.id,
            }
        })
    }

    const handleAddPrincipalDialogClose = () => {
        setInvitePersonDialogOpen(false);
    }

    const handleReInviteBorrowerClick = async (userId: string) => {
        await dispatch(inviteUserToLoan({
            loanId: props.loan.id,
            userId,
        }));
        await dispatch(getLoan(props.loan.id, false, true));
    };

    const onMoveToBorrowingTeam = (loanRole: LoanRoleDto) => {
        contactContext.onMoveLoanRoleToBorrowingTeam(loanRole);
    };
    const onMoveKeyContactToContact = (loanRole: LoanRoleDto) => {
        contactContext.onMoveKeyContactToContact(loanRole);
    }

    const handleSendDigestClick = async (userId: string) => {
        messagesContext.onDigestClick([userId]);
    }

    const companyLenderRoles = React.useMemo(() => {
        if (!props.loan?.loanRoles) {
            return [];
        }
        return employees.map(lenderUser => {
            const existingRole = props.loan.loanRoles.find((role) => role.user.id === lenderUser.id);
            return ({
                id: !!existingRole ? existingRole.id : null,
                loan: props.loan.id,
                role: !!existingRole ? existingRole.role : RoleType.Lender,
                user: lenderUser,
                canAcceptFiles: true,
                visibleToBorrower: true,
            }) as LoanRole;
        })
    }, [employees, props.loan?.id, props.loan?.loanRoles]);

    const keyContacts: TeamGroup[] = React.useMemo(() => {
        if (!props.loan) {
            return [];
        }
        const existingKeyContacts = props.loan.loanRoles
            .filter((role) => role.role === "CONTACT" && role.keyContact)
            .map(loanRole => {
                const roleProgress = getUserLoanProgress(loanRole.user.id, loanByIdData?.userProgress);
                return ({
                    ...loanRole,
                    ...roleProgress,
                    isMe: loanRole.user.id === loggedInUser.id,
                })
            })
            .sort((a, b) => {
                const aFullName = `${a.user.givenName} ${a.user.familyName} ${a.user.id}`;
                const bFullName = `${b.user.givenName} ${b.user.familyName} ${b.user.id}}`;
                return aFullName.localeCompare(bFullName);
            });
        return existingKeyContacts.reduce((acc, keyContact) => {
            // create 2 groups of key contacts leads and non leads
            const group = acc.find(group => group.title === 'KEY_CONTACT');
            if (group) {
                group.roles.push(keyContact);
            } else {
                acc.push({
                    title: 'KEY_CONTACT',
                    roles: [keyContact]
                })
            }
            return acc;
        }, [])
    }, [loanByIdData?.userProgress, loggedInUser.id, props.loan]);

    const lenders: TeamGroup[] = React.useMemo(() => {
        if (!props.loan) {
            return [];
        }
        const existingLenders = props.loan.loanRoles
            .filter((role) => role.role.includes('LENDER'))
            .map(loanRole => {
                const roleProgress = getUserLoanProgress(loanRole.user.id, loanByIdData?.userProgress);
                return ({
                    ...loanRole,
                    ...roleProgress,
                    isMe: loanRole.user.id === loggedInUser.id,
                })
            }).sort((a, b) => {
                // Lead Lender first then manager then associate then lender then underwriter then everyone else
                const aRole = a.role;
                const bRole = b.role;
                const aFullName = `${a.user.givenName} ${a.user.familyName} ${a.user.id}`;
                const bFullName = `${b.user.givenName} ${b.user.familyName} ${b.user.id}}`;

                if (aRole === bRole) {
                    return aFullName.localeCompare(bFullName);
                }
                if (aRole === RoleType.LeadLender) {
                    return -1;
                }
                if (bRole === RoleType.LeadLender) {
                    return 1;
                }
                if (aRole === RoleType.Manager) {
                    return -1;
                }
                if (bRole === RoleType.Manager) {
                    return 1;
                }
                if (aRole === RoleType.AssociateLender) {
                    return -1;
                }
                if (bRole === RoleType.AssociateLender) {
                    return 1;
                }
                if (aRole === RoleType.Lender) {
                    return -1;
                }
                if (bRole === RoleType.Lender) {
                    return 1;
                }
                if (aRole === RoleType.Underwriter) {
                    return -1;
                }
                if (bRole === RoleType.Underwriter) {
                    return 1;
                }
                return aFullName.localeCompare(bFullName);
            });
        return existingLenders.reduce((acc, lender) => {
            // create 2 groups of lenders leads and non leads
            const group = acc.find(group => group.title === (lender.role === RoleType.LeadLender ? 'LEAD' : 'MEMBER'));
            if (group) {
                group.roles.push(lender);
            } else {
                acc.push({
                    title: lender.role === RoleType.LeadLender ? 'LEAD' : 'MEMBER',
                    roles: [lender]
                })
            }
            return acc;
        }, [])
    }, [loanByIdData?.userProgress, loggedInUser.id, props.loan]);

    const borrowers: TeamGroup[] = React.useMemo(() => {
        if (!props.loan) {
            return [];
        }

        const existingBorrowers = props.loan.loanRoles
            .filter((role) => role.role === RoleType.Borrower || role.role === RoleType.LeadBorrower)
            .map(loanRole => {
                const hasPendingInvite = props.loan.pendingInviteUsers.some((inviteUser) => inviteUser.id === loanRole.user.id);
                const roleProgress = getUserLoanProgress(loanRole.user.id, loanByIdData?.userProgress);
                return ({
                    ...loanRole,
                    user: {
                        ...loanRole.user,
                        pendingInvite: hasPendingInvite,
                    },
                    isMe: loanRole.user.id === loggedInUser.id,
                    ...roleProgress,
                })
            })
            .sort((a, b) => {
                // lead borrower then everything  else
                const aRole = a.role;
                const bRole = b.role;
                const aFullName = `${a.user.givenName} ${a.user.familyName} ${a.user.id}`;
                const bFullName = `${b.user.givenName} ${b.user.familyName} ${b.user.id}}`;
                if (aRole === bRole) {
                    return aFullName.localeCompare(bFullName);
                }
                if (aRole === RoleType.LeadBorrower) {
                    return -1;
                }
                if (bRole === RoleType.LeadBorrower) {
                    return 1;
                }
                return aFullName.localeCompare(bFullName);

            })
        return existingBorrowers.reduce((acc, borrower) => {
            // create 2 groups of borrowers leads and non leads
            const group = acc.find(group => group.title === (borrower.role === RoleType.LeadBorrower ? 'LEAD' : 'MEMBER'));
            if (group) {
                group.roles.push(borrower);
            } else {
                acc.push({
                    title: borrower.role === RoleType.LeadBorrower ? 'LEAD' : 'MEMBER',
                    roles: [borrower]
                })
            }
            return acc;
        }, [])
    }, [loanByIdData?.userProgress, loggedInUser.id, props.loan]);

    const handleAddMeClick = async () => {
        await dispatch(addLenderToLoan({
            loanId: props.loan.id,
            payload: {
                contactVisibleToBorrowers: false,
                contactVisibleToLenders: false,
                newToLoan: true,
                appUser: loggedInUser.id,
                leadLender: false,
                leadBorrower: false,
                role: loggedInUser.loggedCompanyRole,
                canAcceptFiles: true,
                visibleToBorrower: false,
                borrowerType: null,
                contactRelation: null
            }
        }))
    }

    const handleAddContact = (loanRole: LoanRoleDto) => {
        contactContext.onSetIsAddContactLoanRole(loanRole);
    }

    const onMoveToContact = (loanRole: LoanRoleDto) => {
        contactContext.onMoveLoanRoleToContact(loanRole);
    };

    const onMoveToKeyContact = (loanRole: LoanRoleDto) => {
        contactContext.onMoveLoanRoleToKeyContact(loanRole);
    };

    const handleAddKeyContactClick = () => {
        router.push({
            pathname: Route.LOAN_CONTACT_KEY_CONTACT_CREATE,
            query: {
                loanId: props.loan.id,
            }
        });
    };

    const addLenderDisabled = companyLenderRoles.length === 0;

    return {
        loggedInUser,
        lenders,
        borrowers,
        addLenderDisabled,
        keyContacts,
        handleSendMessageClick,
        handleAddLenderClick,
        handleAddKeyContactClick,
        handleAddBorrowerClick,
        handleReInviteBorrowerClick,
        handleEditClick,
        handleDeleteRole,
        handleAddPrincipalDialogClose,
        handleAddMeClick,
        handleSendDigestClick,
        onAddContact: handleAddContact,
        onMoveToBorrowingTeam,
        onMoveToContact,
        onMoveToKeyContact,
        onMoveKeyContactToContact,
        userRole,
        employees,
        lenderLabels,
        applicantLabels,
        invitePersonDialogOpen,
        borrowerActionsDisabled: !userRole || !roleCan(userRole, Permission.UpdateBorrower),
        creditScoreEnabled: roleCan(userRole, Permission.SeeBorrowersCreditScore),
        tasksDisabled: !roleCan(userRole, Permission.SeeBorrowersTasks),
    } as const;
}
